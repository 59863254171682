// extracted by mini-css-extract-plugin
export var marketingForce = "index-module--marketingForce--jacpj";
export var service = "index-module--service--cOvXI";
export var service_title = "index-module--service_title--EVBCx";
export var flex_group = "index-module--flex_group--aTUSl";
export var flex_item = "index-module--flex_item--3jHhQ";
export var text_big = "index-module--text_big--RFBM8";
export var text_normal = "index-module--text_normal--QvnB2";
export var title = "index-module--title--2lugn";
export var chrome_intro = "index-module--chrome_intro--Np0ZG";
export var render_item = "index-module--render_item--R48Is";
export var img_container = "index-module--img_container--A5ON3";
export var text_container = "index-module--text_container--uUb1D";
export var text_title = "index-module--text_title--cB4ED";
export var text_intro = "index-module--text_intro--r4qrJ";
export var flex_box = "index-module--flex_box--uY8ZK";
export var line = "index-module--line--AE7rX";